@import '~styles/mixins';

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
}


.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}

.textarea {
  width: 100%;
  height: 160px;
  padding: 16px 20px;
  background: color(var(--background-primary));
  border: 1px solid color(var(--color-primary), 0.2);
  border-radius: 30px;
  resize: none;
  outline: none;
}
